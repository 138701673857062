import React from "react";
import Seo from "../../components/Seo";
import NavBackBtn from "../../components/UI/navBackBtn";
import Layout from "../../components/layouts/index";
import FernwehVideo from "../../assets/videos/fernweh_dp_lvl3.mp4";

const Fernweh = () => {
  return (
    <Layout>
      <Seo title="Fernweh-Scwheiz" />
      <article className="single-project">
        <div className="single-project-text">
          <div className="heading-box">
            <NavBackBtn url={"projects/"} label={"BACK TO PROJECTS"} />
            <h2 className="main-heading">Fernweh Schweiz</h2>
            <a
              className="link-small"
              href="https://fernweh-schweiz.ch/"
              target="example"
              rel="noopener"
            >
              fernweh-schweiz.ch
            </a>
          </div>
          <div className="info-box">
            <div className="what-how-box">
              <div className="what-box">
                <h3 className="small-heading">WHAT</h3>
                <span className="label-small">Design</span>
                <span className="label-small">Programming</span>
              </div>
              <div className="how-box">
                <h3 className="small-heading">HOW</h3>
                <span className="label-small">Context API</span>
                <span className="label-small">Figma</span>
                <span className="label-small">React.js</span>
                <span className="label-small">Sass</span>
              </div>
            </div>

            <div className="text-box">
              <p className="scndry-text">
                My challenge was to design and program a fully responsive web
                site inspired by Fernweh Schweiz newly created travel brochure.
                I took the mobile-first approach when designing and incorporated
                colours and theme’s from the brochure and then added a personal
                twist. When programming the website I used React.js, coupled
                with Context API to control language rendering. For a deeper
                dive, check out the links to the GitHub repo or the Figma
                document.
              </p>
              <div className="under-hood-box">
                <h3 className="small-heading">UNDER THE HOOD</h3>
                <a
                  className="link-small"
                  href="https://github.com/ghr84/fernweh"
                  target="example"
                  rel="noopener"
                >
                  github.com/ghr84/fernweh
                </a>
                <a
                  className="link-small"
                  href="https://www.figma.com/file/zMR5hmPCnQ7Cd5Az7W3Oxl/Fernweh-Schweiz?node-id=8%3A0"
                  target="example"
                  rel="noopener"
                >
                  figma.com/fernweh-schweiz
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="single-project-video-container fernweh">
          <video
            className="single-project-video"
            autoPlay
            loop
            muted
            playsinline
          >
            <source src={FernwehVideo} type="video/mp4"></source>
            <h2 className="scndry-heading">
              Your browser does not support HTML5 video.
            </h2>
          </video>
        </div>
      </article>
    </Layout>
  );
};

export default Fernweh;
