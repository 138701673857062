import React from "react";
import NavLink from "./navLink";
import { IoIosArrowBack } from "@react-icons/all-files/io/IoIosArrowBack";

const navBackBtn = ({ url, label }) => {
  return (
    <div className="nav-back-btn">
      <IoIosArrowBack size={12} className="btn-arrow" />
      <NavLink url={url} label={label} />
    </div>
  );
};

export default navBackBtn;
